import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms'

import { NgbAccordionModule, NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { NavComponent } from './component/nav/nav.component';
import { HomeComponent } from './component/home/home.component';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { ProfileComponent } from './component/profile/profile.component';
import { RecipesComponent } from './component/recipes/recipes.component';
import { UnitComponent } from './component/unit/unit.component';
import { TypeComponent } from './component/type/type.component';
import { IngredientComponent } from './component/ingredient/ingredient.component';
import { SourceComponent } from './component/source/source.component';
import { RecipeComponent } from './component/recipe/recipe.component';
import { AvatarComponent } from './component/avatar/avatar.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    SignInComponent,
    ProfileComponent,
    RecipesComponent,
    UnitComponent,
    TypeComponent,
    IngredientComponent,
    SourceComponent,
    RecipeComponent,
    AvatarComponent
  ],
  imports: [
    NgbAccordionModule,
    NgbDropdownModule,
    NgbTypeaheadModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
  ],
  providers: [
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
