import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms'
import { deepCopy } from "@angular-devkit/core/src/utils/object";
import { Profile, SupabaseService } from '../../supabase.service'
import { User } from '@supabase/supabase-js';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  loading: boolean = false;
  user!: User
  profile!: Profile

  updateProfileForm = this.formBuilder.group({
    username: '',
    full_name: '',
    website: '',
    avatar_url: '',
  })

  constructor(private readonly supabase: SupabaseService, private formBuilder: FormBuilder) {}

  async ngOnInit(): Promise<void> {
    const session = this.supabase.session;

    if (session && session.user) {
      this.user = session.user
      await this.getProfile(this.user)
    }

    const { username, full_name, website, avatar_url } = this.profile
    this.updateProfileForm.patchValue({
      username,
      full_name,
      website,
      avatar_url,
    })
  }

  async getProfile(user: User) {
    try {
      this.loading = true
      
      let { data: profile, error, status } = await this.supabase.profile(user)

      if (error && status !== 406) {
        throw error
      }

      if (profile) {
        this.profile = profile
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  get avatarUrl() {
    return this.updateProfileForm.value.avatar_url as string
  }

  async updateAvatar(event: string): Promise<void> {
    this.updateProfileForm.patchValue({
      avatar_url: event,
    })
    await this.updateProfile()
  }

  async updateProfile(): Promise<void> {
    try {
      this.loading = true

      let updateProfile = deepCopy(this.profile);
      updateProfile.username = this.updateProfileForm.value.username as string
      updateProfile.full_name = this.updateProfileForm.value.full_name as string
      updateProfile.website = this.updateProfileForm.value.website as string
      updateProfile.avatar_url = this.updateProfileForm.value.avatar_url as string
      

      const { error } = await this.supabase.updateProfile(updateProfile)
      if (error) throw error
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async signOut() {
    await this.supabase.signOut()
  }
}
