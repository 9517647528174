<div class="container">
  <div class="row justify-content-center my-5">
    <form [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()" class="form-widget">
      <div class="col-4">
        <avatar-component [avatarUrl]="this.avatarUrl" (upload)="updateAvatar($event)"> </avatar-component>
        
        <div class="row">
          <div class="col mb-2">
            <label for="email" class="form-label">Email:</label>
            <input id="email" type="text" [value]="user.email" disabled class="form-control form-control-sm">
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <label for="name" class="form-label">User:</label>
            <input formControlName="username" id="username" type="text" class="form-control form-control-sm">
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <label for="name" class="form-label">Name (vollständig):</label>
            <input formControlName="full_name" id="full_name" type="text" class="form-control form-control-sm">
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <label for="website" class="form-label">Website:</label>
            <input formControlName="website" id="website" type="url" class="form-control form-control-sm">
          </div>
        </div>
        <div class="row">
          <div class="col d-grid">
            <button type="submit" class="button primary block" [disabled]="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
              {{ loading ? 'Loading ...' : 'Update' }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  {{ profile | json }}
</div>