<div class="container" *ngIf="recipe">
    <div class="clearfix">
        <p class="h3 float-start">{{ recipe.name }}</p>
        <button type="button" class="btn btn-light float-end" (click)="goBack()">
            <i class="bi bi-arrow-left-square"></i>
        </button>
    </div>
    <div ngbAccordion>
        <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton>Details</button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <div class="row" *ngIf="recipe.source">
                            <div class="col-md-1">Quelle</div>
                            <div class="col-md-4">
                                <i *ngIf="recipe.source.source === 'Person'" class="bi bi-person"></i>
                                <i *ngIf="recipe.source.source === 'Heft'" class="bi bi-journal"></i>
                                <i *ngIf="recipe.source.source === 'Buch'" class="bi bi-book"></i>
                                <i *ngIf="recipe.source.source === 'Webseite'" class="bi bi-globe"></i>
                                {{ recipe.source.name }}
                            </div>
                            <div class="col-md-2" *ngIf="recipe.source.url"><a href="{{ recipe.source.url }}">{{
                                    recipe.source.url }}</a></div>
                        </div>
                        <div class="row" *ngIf="recipe.type && recipe.type.length > 0">
                            <div class="col-md-1">Art</div>
                            <div class="col-md">
                                <ul class="list-group list-group-horizontal-md">
                                    <li class="list-group-item" *ngFor="let t of recipe.type">{{ t.type }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row" *ngIf="recipe.owner">
                            <div class="col-md-1">Inhaber</div>
                            <div class="col-md">{{ recipe.owner }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md">Erzeugt am {{ recipe.created_at | date:'medium' }}</div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="recipe.source == null || recipe.source.source == 'Person'; then details; else external">
    </ng-container>

    <ng-template #details>
        <div *ngIf="ingredients.length > 0">
            <h4 class="mt-2">Zutaten</h4>
            <div class="table-responsive">
                <table class="table table-sm ingredients">
                    <tbody>
                        <tr *ngFor="let i of ingredients">
                            <td>{{ i.amount }} {{ i.unit.unit }}</td>
                            <td>{{ i.ingredient.ingredient }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="instructions.length > 0">
            <h4 class="mt-2">Zubereitung</h4>
            <div class="table-responsive">
                <table class="table table-sm instructions">
                    <tbody>
                        <tr *ngFor="let instruction of instructions ; index as i">
                            <td>{{ i + 1 }}</td>
                            <td>{{ instruction.instruction }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-template>
    <ng-template #external>
        <div class="row mt-2">
            <div class="col-md-2" *ngIf="recipe.source.source == 'Webseite'">
                <a class="btn btn-primary" href="{{ recipe.source.url }}" target="_blank" role="button">
                    <i class="bi bi-globe"></i>{{ recipe.source.name }}
                </a>
            </div>
            <div class="col-md-4" *ngIf="recipe.source.source == 'Buch'">
                <i class="bi bi-book"></i>
                {{ recipe.source.name }} {{ recipe.source_detail }}
            </div>
            <div class="col-md-4" *ngIf="recipe.source.source == 'Heft'">
                <i class="bi bi-journal"></i>
                {{ recipe.source.name }} {{ recipe.source_detail }}
            </div>
        </div>
    </ng-template>
</div>