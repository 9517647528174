<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="signInForm" (ngSubmit)="signIn()" class="form-widget">
            <div class="row">
              <div class="col mb-2">
                <label for="email" class="form-label">Email:</label>
                <input id="email" formControlName="email" class="form-control form-control-sm" type="email"
                  placeholder="Your email" />
                  <div *ngIf="signInForm.get('email')?.invalid && (signInForm.get('email')?.dirty || signInForm.get('email')?.touched)">
                  Please enter a valid email address.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col d-grid">
                <button type="submit" class="btn btn-sm btn-primary block" [disabled]="signInForm.invalid || loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    *ngIf="loading"></span>
                  {{ loading ? 'Loading' : 'Send magic link' }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>