import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SupabaseService } from '../../supabase.service'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent  implements OnInit {

  session = this.supabaseService.session
  collapsed = true;

  constructor(private router: Router,
              private supabaseService: SupabaseService) {
    this.session = this.supabaseService.session;
  }

  public ngOnInit(): void {
    this.supabaseService.authChanges((_, session) => this.session = session);
  }

  public isAuthenticated(): boolean {
    if (this.session) {
      return true;
    }
    return false;
  }

  public signOut(): void {
    this.supabaseService.signOut()
    .then(() => {
      this.router.navigate(['/login']);
    });
  }

}
