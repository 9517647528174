import { Injectable } from '@angular/core'
import {
  AuthChangeEvent,
  AuthSession,
  createClient,
  Session,
  SupabaseClient,
  User,
} from '@supabase/supabase-js'
import { environment } from 'src/environments/environment'

import type { Database } from './supabase-types';
import { PostgrestError } from '@supabase/supabase-js';

export type DbResult<T> = T extends PromiseLike<infer U> ? U : never
export type DbResultOk<T> = T extends PromiseLike<{ data: infer U }> ? Exclude<U, null> : never
export type DbResultErr = PostgrestError

export type Profile = Database['public']['Tables']['profiles']['Row'];
export type Source = Database['public']['Tables']['source']['Row'];
export type Type = Database['public']['Tables']['type']['Row'];
export type Unit = Database['public']['Tables']['unit']['Row'];
export type UnitAdd = Database['public']['Tables']['unit']['Insert'];
export type Ingredient = Database['public']['Tables']['ingredient']['Row'];
export type Instructions = Database['public']['Tables']['instructions']['Row'];
export type Recipe = Database['public']['Tables']['recipe']['Row'];
export type RecipeSource = Database['public']['Tables']['source']['Row'];
export type RecipeType = Database['public']['Tables']['type']['Row'];
export interface RecipeOverview extends Omit<Recipe, "source"> {
  source: RecipeSource;
  type: RecipeType[];
}
export type IngredientList = Database['public']['Tables']['ingredients_list']['Row'];
export interface IngredientAmount extends Omit<IngredientList, "unit_id" | "ingredient_id"> {
  unit: Unit;
  ingredient: Ingredient;
}
export type Instruction = Database['public']['Tables']['instructions']['Row'];

export interface RecipeDetails extends Omit<Recipe, "source"> {
  source: RecipeSource;
  type: RecipeType[];
  instructions: Instructions
}

const arrayOfAllEnums = <T>() => <U extends T[]>(
  array: U & ([T] extends [U[number]] ? unknown : 'Invalid') & { 0: T }
) => array;
type SourceEnum = Database['public']['Enums']['recipe_source_enum'];
const arrayOfAllSource = arrayOfAllEnums<SourceEnum>();
export const SOURCE_ENUM = arrayOfAllSource(['Buch', 'Heft', 'Webseite', 'Person']);


@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabase: SupabaseClient
  _session: AuthSession | null = null

  constructor() {
    this.supabase = createClient<Database>(environment.supabaseUrl, environment.supabaseKey)
  }

  get session() {
    this.supabase.auth.getSession().then(({ data }) => {
      this._session = data.session
    })
    return this._session
  }

  async profile(user: User) {
    const query = this.supabase.from('profiles').select(`*`).eq('id', user.id).single();
    const p: DbResult<typeof query> = await query;
    return p;
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback)
  }

  signIn(email: string) {
    return this.supabase.auth.signInWithOtp({ email })
  }

  signOut() {
    return this.supabase.auth.signOut()
  }

  updateProfile(profile: Profile) {
    const update = {
      ...profile,
      updated_at: new Date(),
    }

    return this.supabase.from('profiles').upsert(update)
  }

  async addUnit(unit: UnitAdd) {
    const insert = this.supabase.from('unit').insert(unit).select(`*`).single();
    const newUnit : DbResult<typeof insert> = await insert;
    return newUnit;
  }

  downLoadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path)
  }

  uploadAvatar(filePath: string, file: File) {
    return this.supabase.storage.from('avatars').upload(filePath, file)
  }

  async allSources() {
    const query = this.supabase
      .from('source')
      .select(`*`)

    const sources: DbResult<typeof query> = await query;
    return sources;
  }

  async allTypes() {
    const query = this.supabase
      .from('type')
      .select(`*`)

    const types: DbResult<typeof query> = await query;
    return types;
  }

  async allUnits() {
    const query = this.supabase
      .from('unit')
      .select(`*`)

    const units: DbResult<typeof query> = await query;
    return units;
  }

  async allIngredients() {
    const query = this.supabase
      .from('ingredient')
      .select(`*`)

    const ingredients: DbResult<typeof query> = await query;
    return ingredients;
  }

  async allRecipes() {
    const query = this.supabase
      //.from<'recipe', RecipeWithSource>('recipe')
      .from('recipe')
      .select(`*, source(*), type(*)`)

    const recipes: DbResult<typeof query> = await query;
    return recipes;
  }

  async getRecipe(rid: number) {
    const query = this.supabase
      .from('recipe')
      .select(`*, source(*), type(*)`)
      .eq('id', rid).single();

    const recipe: DbResult<typeof query> = await query;
    return recipe;
  }

  async getIngredients4Recipe(rid: number) {
    const query = this.supabase
      .from('ingredients_list')
      .select(`*, ingredient(*), unit(*)`)
      .eq('recipe_id', rid);
    
    const ingredients : DbResult<typeof query> = await query;
    return ingredients;
  }

  async getInstructions4Recipe(rid: number) {
    const query = this.supabase
      .from('instructions')
      .select(`*`)
      .eq('recipe_id', rid);
    
    const instructions : DbResult<typeof query> = await query;
    return instructions;
  }
}