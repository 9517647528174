<div class="container">
    <h3>Übersicht aller Quellen</h3>
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th class="source" scope="col">Art</th>
                <th class="source-name" scope="col">Name</th>
            </tr>
            <tr>
                <th>&nbsp;</th>
                <th class="source" scope="col">
                    <form [formGroup]="filterTable">
                        <select id="table-filtering-search-source" class="form-control form-select" aria-label="Filter für Art" formControlName="filterSource">
                            <option selected value=""></option>
                            <option *ngFor="let source of source_enum">{{ source }}</option>
                        </select>
                    </form>
                </th>
                <th class="source-name" scope="col">
                    <form [formGroup]="filterTable">
                        <input id="table-filtering-search-name" class="form-control" type="text" formControlName="filterName" />
                    </form>
                </th>
            </tr>
        </thead>
        <tbody class="table-group-divider">
            <tr *ngFor="let source of sources$ | async; index as i">
                <th class="row-count" scope="row">{{ i + 1 }}</th>
                <td class="source">
                    <i *ngIf="source.source === 'Person'" class="bi bi-person"></i>
                    <i *ngIf="source.source === 'Heft'" class="bi bi-journal"></i>
                    <i *ngIf="source.source === 'Buch'" class="bi bi-book"></i>
                    <i *ngIf="source.source === 'Webseite'" class="bi bi-globe"></i>
                </td>
                <td class="source-name">
                    <ngb-highlight [highlightClass]="'ngb-highlight-table-search'" [result]="source.name" [term]="getSearchName()"></ngb-highlight>
                </td>
            </tr>
        </tbody>
    </table>
</div>