<div class="container">
    <h3>Übersicht aller Rezepte</h3>

    <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Rezeptname</th>
                    <th scope="col">Art</th>
                    <th scope="col">Quelle</th>
                    <th scope="col">Quelle-Detail</th>
                    <th scope="col">&nbsp;</th>
                </tr>
                <tr>
                    <th>&nbsp;</th>
                    <th class="source-name" scope="col">
                        <form [formGroup]="recipesFilter">
                            <input id="table-filtering-search-name" class="form-control" type="text"
                                formControlName="recipe_name" />
                        </form>
                    </th>
                    <th>&nbsp;</th>
                    <th>
                        <form [formGroup]="recipesFilter">
                            <input id="typeahead-basic" type="text" class="form-control" formControlName="source"
                                [ngbTypeahead]="searchSource" [inputFormatter]="formatterSource"
                                [resultFormatter]="formatterSource" />
                        </form>
                    </th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let recipe of recipes$ | async ; index as i">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                        <a class="link-underline-secondary" routerLink="/recipe/{{ recipe.id }}">
                            <ngb-highlight [highlightClass]="'ngb-highlight-table-search'" [result]="recipe.name" [term]="getSearchRecipeName()"></ngb-highlight>
                        </a>
                    </td>
                    <td>
                        <ul>
                            <li *ngFor="let t of recipe.type">{{ t.type }}</li>
                        </ul>
                    </td>
                    <!-- source begin -->
                    <ng-template [ngIf]="recipe.source" [ngIfElse]="noSource">
                        <td>
                            <i *ngIf="recipe.source.source === 'Person'" class="bi bi-person"></i>
                            <i *ngIf="recipe.source.source === 'Heft'" class="bi bi-journal"></i>
                            <i *ngIf="recipe.source.source === 'Buch'" class="bi bi-book"></i>
                            <i *ngIf="recipe.source.source === 'Webseite'" class="bi bi-globe"></i>
                            {{ recipe.source.name }}
                        </td>
                    </ng-template>
                    <ng-template #noSource>
                        <td>&nbsp;</td>
                    </ng-template>
                    <!-- source end -->
                    <td>{{ recipe.source_detail }}</td>
                    <td>
                        <div class="btn-group" role="group" aria-label="Commands">
                            <button type="button" class="btn btn-light" routerLink="/recipe/{{ recipe.id }}"
                                title="View Recipe">
                                <i class="bi bi-filter-square"></i>
                            </button>
                            <button type="button" class="btn btn-light" disabled title="Edit Recipe">
                                <i class="bi bi-pencil-square"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>