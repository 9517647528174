<ng-template #content let-modal> 
	<div class="modal-header">
		<h4 class="modal-title" id="modal-unit-title">Einheit hinzufügen</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
    <form [formGroup]="newUnit" (ngSubmit)="addUnit()">
	<div class="modal-body">
            <div class="row">
                <div class="col mb-1">
                    <label for="unit">Einheit</label>
                </div>
                <div class="col mb-1">
                    <input formControlName="unit" id="unit" placeholder="z.B. kg">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-outline-dark" [disabled]="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                {{ loading ? 'Loading ...' : 'Speichern' }}
              </button>
        </div>
    </form>
</ng-template>
<div class="container">
    <div class="clearfix">
        <p class="h3 float-start">Übersicht aller Einheiten</p>
        <button type="button" class="btn btn-light float-end" title="Einheit hinzufügen" (click)="open(content)">
            <i class="bi bi-plus-square"></i>
        </button>
    </div>
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th class="unit" scope="col">Einheit</th>
            </tr>
            <tr>
                <th>&nbsp;</th>
                <th class="unit" scope="col">
                    <form>
                        <input id="table-filtering-search" class="form-control" type="text" [formControl]="filter" />
                    </form>
                </th>
            </tr>
        </thead>
        <tbody class="table-group-divider">
            <tr *ngFor="let unit of units$ | async; index as i">
                <th class="row-count" scope="row">{{ i + 1 }}</th>
                <td class="unit">
                    <ngb-highlight [highlightClass]="'ngb-highlight-table-search'" [result]="unit.unit"
                        [term]="filter.value"></ngb-highlight>
                </td>
            </tr>
        </tbody>
    </table>
</div>