<div class="row">
    <div class="col mb-2 text-center">
        <img *ngIf="_avatarUrl" [src]="_avatarUrl" alt="Avatar" class="avatar image rounded"
            style="height: 150px; width: 150px" />
    </div>
    <div class="col mb-2 text-center" *ngIf="!_avatarUrl" class="avatar no-image" style="height: 150px; width: 150px">
    </div>
</div>
<div class="row">
    <div class="col mb-2 text-center">
        <label class="btn btn-primary btn-sm block" for="single">
            {{ uploading ? 'Uploading ...' : 'Upload' }}
        </label>
        <input style="visibility: hidden;position: absolute" type="file" id="single" accept="image/*"
            (change)="uploadAvatar($event)" [disabled]="uploading" />
    </div>
</div>