import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms'

import { SupabaseService, Unit, UnitAdd } from '../../supabase.service'
import { Observable, map, startWith } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'unit-component',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent implements OnInit {

  loading: boolean = false;
  units: Unit[] = [];
  units$: Observable<Unit[]>;
  filter = new FormControl('', { nonNullable: true });
  newUnit = this.formBuilder.group({
    unit: '',
  })

  constructor(private readonly supabase: SupabaseService, private formBuilder: FormBuilder, private modalService: NgbModal) {
    this.units$ = this.filter.valueChanges.pipe(
      startWith(''),
      map((text) => this.search(text)),
    );
  }

  ngOnInit(): void {
    this.supabase.allUnits().then((data) => {
      this.units = data.data as Unit[];
      this.sortAndResetFilter();
    }, (errData) => {
      console.error("error while get units: " + errData)
    });
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-unit-title' });
  }

  sortAndResetFilter(){
    this.units.sort((a, b) => a.unit.localeCompare(b.unit));
    this.filter.patchValue('');
  }

  async addUnit(): Promise<void> {
    this.loading = true
    console.info(this.newUnit.value)

    let newUnit: UnitAdd = {
      unit: this.newUnit.value.unit as string
    };

    this.supabase.addUnit(newUnit)
      .then(data => {
        if (data.error) throw data.error
        if(data.data){
          this.units.push(data.data as Unit);
          this.sortAndResetFilter();
        }
      })
      .catch(error => {
        console.error("error while add unit", error);
        alert(error.message);
      })
      .finally(() => {
        this.loading = false;
        this.modalService.dismissAll('save unit');
      });
  }

  search(text: string): Unit[] {
    return this.units.filter((unit) => {
      return unit.unit.includes(text);
    });
  }
}
