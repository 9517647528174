import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth.guard';

import { HomeComponent } from './component/home/home.component';
import { ProfileComponent } from './component/profile/profile.component';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { RecipesComponent } from './component/recipes/recipes.component';
import { UnitComponent } from './component/unit/unit.component';
import { TypeComponent } from './component/type/type.component';
import { IngredientComponent } from './component/ingredient/ingredient.component';
import { SourceComponent } from './component/source/source.component';
import { RecipeComponent } from './component/recipe/recipe.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: SignInComponent,
  },
  {
    path: 'recipes',
    component: RecipesComponent,
    canActivate: [AuthGuard]
  },
  { path: 'recipe/:id',
    component: RecipeComponent
  },
  {
    path: 'sources',
    component: SourceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'types',
    component: TypeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'units',
    component: UnitComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ingredients',
    component: IngredientComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
