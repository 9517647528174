<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">T&P Rezepte</a>

    <button class="navbar-toggler" type="button" (click)="collapsed = !collapsed" data-bs-toggle="collapse" data-bs-target="#navbarContent"
      aria-controls="navbarSupportedContent" [attr.aria-expanded]="!collapsed" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div id="navbarContent" class="navbar-collapse" [class.collapse]="collapsed">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" routerLink="/login" routerLinkActive="active" ariaCurrentWhenActive="page"
            *ngIf="!isAuthenticated()">Sign in</a>
        </li>
        <li class="nav-item" *ngIf="isAuthenticated()">
          <a class="nav-link" routerLink="/recipes" routerLinkActive="active" ariaCurrentWhenActive="page"
            >Rezepte</a>
        </li>
        <li class="nav-item" ngbDropdown *ngIf="isAuthenticated()">
          <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarBasic" role="button">Basisdaten</a>
          <div ngbDropdownMenu aria-labelledby="navbarBasic" class="dropdown-menu">
            <a ngbDropdownItem routerLink="/sources" routerLinkActive="active" ariaCurrentWhenActive="page">Quellen</a>
            <a ngbDropdownItem routerLink="/types" routerLinkActive="active" ariaCurrentWhenActive="page">Typen</a>
            <a ngbDropdownItem routerLink="/units" routerLinkActive="active" ariaCurrentWhenActive="page">Einheiten</a>
            <a ngbDropdownItem routerLink="/ingredients" routerLinkActive="active" ariaCurrentWhenActive="page">Zutaten</a>
          </div>
        </li>
        <li class="nav-item" ngbDropdown *ngIf="isAuthenticated()">
          <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarUser" role="button">User</a>
          <div ngbDropdownMenu aria-labelledby="navbarBasic" class="dropdown-menu">
            <a ngbDropdownItem routerLink="/profile" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="$event.preventDefault()">Profil</a>
            <a ngbDropdownItem routerLink="" (click)="signOut()">Sign out</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>