import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { IngredientAmount, Instruction, RecipeOverview, SupabaseService } from 'src/app/supabase.service';

@Component({
  selector: 'recipe-component',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class RecipeComponent {

  recipe!: RecipeOverview;
  ingredients: IngredientAmount[] = [];
  instructions: Instruction[] = [];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private readonly supabase: SupabaseService
  ) { }

  ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.supabase.getRecipe(id).then((data) => {
        this.recipe = data.data as RecipeOverview;
      }, (errData) => {
        console.error("error while get recipe: " + errData)
      });
      this.supabase.getIngredients4Recipe(id).then((data) => {
        this.ingredients = data.data as IngredientAmount[];
        console.log(this.ingredients)
      }, (errData) => {
        console.error("error while get ingredients for recipe: " + errData)
      });
      this.supabase.getInstructions4Recipe(id).then((data) => {
        this.instructions = data.data as Instruction[];
        this.instructions.sort((a, b) => a.step - b.step);
        console.log(this.instructions)
      }, (errData) => {
        console.error("error while get instructions for recipe: " + errData)
      });
  }

  goBack(): void {
    this.location.back();
  }
}
