import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms'

import { Ingredient, SupabaseService } from '../../supabase.service'
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'ingredient-component',
  templateUrl: './ingredient.component.html',
  styleUrls: ['./ingredient.component.scss']
})
export class IngredientComponent implements OnInit{

  ingredients: Ingredient[] = [];
  ingredients$: Observable<Ingredient[]>;
  filter = new FormControl('', { nonNullable: true });

  constructor(private readonly supabase: SupabaseService) {
    this.ingredients$ = this.filter.valueChanges.pipe(
			startWith(''),
			map((text) => this.search(text)),
		);
  }

  ngOnInit(): void {
    this.supabase.allIngredients().then((data) => {
      this.ingredients = data.data as Ingredient[];
      this.ingredients.sort((a, b) => a.ingredient.localeCompare(b.ingredient));
      this.filter.patchValue('');
    }, (errData) => {
      console.error("error while get ingredients: "+ errData)
    });
  }

  search(text: string): Ingredient[] {
    return this.ingredients.filter((ingredient) => {
      return ingredient.ingredient.includes(text);
    });
  }
}
