import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { SupabaseService, RecipeOverview, Source, RecipeType } from '../../supabase.service'
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit {

  recipes: RecipeOverview[] = [];
  recipes$: Observable<RecipeOverview[]>;
  recipesFilter = this.fb.group({
    recipe_name: this.fb.control<string>('', { nonNullable: true }),
    source: this.fb.control<Source | null>(null),
  });
  sources: Source[] = [];

  constructor(private readonly supabase: SupabaseService, private fb: FormBuilder) {
    this.recipes$ = this.recipesFilter.valueChanges.pipe(
      startWith(),
      map(f => this.search(f))
    );
  }

  ngOnInit(): void {
    this.supabase.allRecipes().then((data) => {
      //console.log(data)
      this.recipes = data.data as RecipeOverview[]
      this.recipes.sort((a, b) => a.name.localeCompare(b.name));
      this.recipes.forEach(r => {
        r.source = r.source as Source
      });
      this.recipes.forEach(r => {
        r.type = r.type as RecipeType[]
        r.type.sort((a, b) => a.type.localeCompare(b.type));
      });
      this.recipesFilter.patchValue({
        recipe_name: '',
        source: null,
      });
      //console.log(this.recipes)
    }, (errData) => {
      console.error("error while get recipes: " + errData)
    });
    this.supabase.allSources().then((data) => {
      this.sources = data.data as Source[];
      this.sources.sort((a, b) => a.name.localeCompare(b.name));
    }, (errData) => {
      console.error("error while get sources: " + errData)
    });
  }

  search(f: Partial<{ recipe_name: string; source: Source | null; }>): RecipeOverview[] {
    let retRecipes = this.recipes;
    if (f.recipe_name !== undefined) {
      let fName = f.recipe_name;
      retRecipes = retRecipes.filter((r) => {
        return r.name.includes(fName);
      });
    }
    if (f.source !== null && f.source !== undefined && typeof f.source === "object") {
      let fs = f.source;
      retRecipes = retRecipes.filter((r) => {
        return r.source === null ? false : r.source.id == fs.id;
      });
    }
    return retRecipes;
  }

  searchSource: OperatorFunction<string, readonly Source[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === '' ? this.sources : this.sources.filter((s) => s.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
      ),
    );

  formatterSource = (s: Source) => s.name;

  getSearchRecipeName() {
    let rn = this.recipesFilter.get('recipe_name');
    if (rn == null) {
      return '';
    }
    else {
      return rn.value
    }
  }
}
