<div class="container">
    <h3>Übersicht aller Zutaten</h3>
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th class="ingredient" scope="col">Zutat</th>
            </tr>
            <tr>
                <th>&nbsp;</th>
                <th class="ingredient" scope="col">
                    <form>
                        <input id="table-filtering-search" class="form-control" type="text" [formControl]="filter" />
                    </form>
                </th>
            </tr>
        </thead>
        <tbody class="table-group-divider">
            <tr *ngFor="let ingredient of ingredients$ | async; index as i">
                <th class="row-count" scope="row">{{ i + 1 }}</th>
                <td class="ingredient">
                    <ngb-highlight [highlightClass]="'ngb-highlight-table-search'" [result]="ingredient.ingredient" [term]="filter.value"></ngb-highlight>
                </td>
            </tr>
        </tbody>
    </table>
</div>