// profile.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SupabaseService } from './supabase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private supabase: SupabaseService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    let session = this.supabase.session;
    if (session) {
      return true;
    }
    else {
      // Redirect to the /login route, while capturing the current url so we can redirect after login
      this.router.navigate(['/login'], {
        queryParams: { redirect_url: state.url }
      });
      return false;
    }
  }

}