import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms'

import { SupabaseService, Type } from '../../supabase.service'
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'type-component',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss']
})
export class TypeComponent implements OnInit{

  types: Type[] = [];
  types$: Observable<Type[]>;
  filter = new FormControl('', { nonNullable: true });

  constructor(private readonly supabase: SupabaseService) {
    this.types$ = this.filter.valueChanges.pipe(
			startWith(''),
			map((text) => this.search(text)),
		);
  }

  ngOnInit(): void {
    this.supabase.allTypes().then((data) => {
      this.types = data.data as Type[];
      this.types.sort((a, b) => a.type.localeCompare(b.type));
      this.filter.patchValue('');
    }, (errData) => {
      console.error("error while get type: "+ errData)
    });
  }

  search(text: string): Type[] {
    return this.types.filter((type) => {
      return type.type.includes(text);
    });
  }
}
