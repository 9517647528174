import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms'

import { Source, SOURCE_ENUM, SupabaseService } from '../../supabase.service'
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'source-component',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss']
})
export class SourceComponent implements OnInit {

  source_enum = SOURCE_ENUM;
  sources: Source[] = [];
  sources$: Observable<Source[]>;
  filterTable: FormGroup<{
    filterSource: FormControl<string>,
    filterName: FormControl<string>
  }>;

  constructor(private readonly supabase: SupabaseService) {
    this.filterTable = new FormGroup({
      filterSource: new FormControl<string>('', { nonNullable: true }),
      filterName: new FormControl<string>('', { nonNullable: true })
    });
    this.sources$ = this.filterTable.valueChanges.pipe(
      startWith(),
      map(f => this.search(f))
    );
  }

  ngOnInit(): void {
    this.supabase.allSources().then((data) => {
      this.sources = data.data as Source[];
      this.sources.sort((a, b) => a.name.localeCompare(b.name));
      this.filterTable.patchValue({
        filterSource: '',
        filterName: '',
      });
    }, (errData) => {
      console.error("error while get sources: " + errData)
    });
  }

  search(f: Partial<{ filterSource: string, filterName: string }>): Source[] {
    let retSources = this.sources;
    if (f.filterSource !== undefined) {
      retSources = retSources.filter((source) => {
        if (f.filterSource == "")
          return true;
        else
          return source.source != null && source.source == f.filterSource;
      });
    }
    if (f.filterName !== undefined) {
      let fName = f.filterName
      retSources = retSources.filter((source) => {
        return source.name.includes(fName);
      });
    }
    return retSources;
  }

  getSearchName() {
    let fn = this.filterTable.get('filterName');
    if (fn == null) {
      return '';
    }
    else {
      return fn.value
    }
  }
}
